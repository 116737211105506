import React, { useCallback } from 'react';

import { IconsEnum } from '../../../../../../../assets/icons/types';

import { OnLightboxOpenAction } from '../../../../../../../helpers/LightboxWrapper';

import { ButtonHelper } from '../../../../../../../helpers/buttons/ButtonHelper';
import { Icon } from '../../../../../../../helpers/Icon';
import {
  ImageHelper,
  ImageItemImageVersions
} from '../../../../../../../helpers/ImageHelper';

import { Files } from '../../../../../../../utils/Files';

const srcSetVersions = {
  '1x': ImageItemImageVersions.MiniThumb48x48,
  '2x': ImageItemImageVersions.MiniThumb96x96,
  '3x': ImageItemImageVersions.MiniThumb144x144
};

import { SelectedProductsListItemPreviewSelectedProduct } from './SelectedProductsListItemPreview.types';

interface SelectedProductsListItemPreviewProps {
  selectedProduct: SelectedProductsListItemPreviewSelectedProduct;
  onLightboxOpen: OnLightboxOpenAction;
}

function SelectedProductsListItemPreview({
  selectedProduct,
  onLightboxOpen
}: SelectedProductsListItemPreviewProps) {
  const product = selectedProduct.product;

  const handleOpenLightbox = useCallback(() => {
    if (product?.image) {
      onLightboxOpen(product?.image);
    }
  }, [product, onLightboxOpen]);

  return (
    <div className="aspect-h-1 flex-shrink-0 h-12 overflow-hidden relative rounded-md w-12">
      {product?.image && Files.isImage(product?.image?.file) ? (
        <ButtonHelper
          dataGa="selected-product-open-lightbox-button"
          className="absolute inset-0"
          onClick={handleOpenLightbox}
        >
          <ImageHelper
            className="h-12 w-12"
            src={product.image.file}
            version={ImageItemImageVersions.MiniThumb48x48}
            srcSetVersions={srcSetVersions}
            alt={product.name}
            loading="lazy"
          />
        </ButtonHelper>
      ) : (
        <div className="flex items-center justify-center text-gray-600 absolute inset-0">
          <Icon icon={IconsEnum.PHOTOGRAPH_OUTLINE} className="h-12 w-12" />
        </div>
      )}
    </div>
  );
}

export default SelectedProductsListItemPreview;
