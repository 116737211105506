import React, { useCallback } from 'react';
import cl from 'classnames';
import isEmpty from 'lodash/isEmpty';

import { SelectedProductsSidebarFooterProductsSet } from './SelectedProductsSidebarFooter.types';

import {
  UpdateProductsSetCacheAction,
  CustomProductsSetAttachAction,
  ProductsSetSelectedProductsID
} from '../../../../../../productsSets/productsSetsTypes';

import { useCurrentUser } from '../../../../../../../auth/hooks/useAuth';

import { SelectedProductsSidebarActionsPopover } from '../SelectedProductsSidebarActionsPopover';
import { SelectedProductsSidebarAttachModalButton } from '../SelectedProductsSidebarAttachModalButton';
import { DownloadProductsSetButton } from '../../../../../../productsSets/components/buttons/DownloadProductsSetButton';

import { PureButtonHelper } from '../../../../../../../helpers/buttons/PureButtonHelper';
import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';

import { words } from '../../../../../../../locales/keys';

import { ProductsSetsPermissions } from '../../../../../../productsSets/productsSetsConstants';

const selectedProductsSidebarActionsPermissions = [
  ProductsSetsPermissions.READ_PRODUCTS_SET_DOWNLOAD_BUTTON
];

interface SelectedProductsSidebarFooterProps {
  productsSet: SelectedProductsSidebarFooterProductsSet;
  checkedSelectedProductIds: ProductsSetSelectedProductsID[];
  onCustomAttach?: CustomProductsSetAttachAction<SelectedProductsSidebarFooterProductsSet>;
  onClose: () => void;
  updateProductsSetCache: UpdateProductsSetCacheAction<SelectedProductsSidebarFooterProductsSet>;
}

function SelectedProductsSidebarFooter({
  productsSet,
  checkedSelectedProductIds,
  onCustomAttach,
  onClose,
  updateProductsSetCache
}: SelectedProductsSidebarFooterProps) {
  const currentUser = useCurrentUser();

  const handleCustomAttach = useCallback(() => {
    if (onCustomAttach && productsSet) {
      onCustomAttach(productsSet, checkedSelectedProductIds);
    }
  }, [onCustomAttach, productsSet, checkedSelectedProductIds]);

  const withActionsPopover = selectedProductsSidebarActionsPermissions.some(
    (action) => currentUser.hasPermissions(action)
  );

  return (
    <div className="justify-between flex space-x-2">
      <CheckPermissions
        action={ProductsSetsPermissions.READ_PRODUCTS_SET_DOWNLOAD_BUTTON}
      >
        <DownloadProductsSetButton
          className="font-medium hover:bg-gray-200 items-center px-4 py-2 rounded-md text-gray-700 text-sm dark:hover:bg-gray-700 dark:text-gray-300 focus:ring-offset-0"
          productsSetUuid={productsSet.uuid}
        />
      </CheckPermissions>

      <span className="relative z-0 inline-flex shadow-sm rounded-md">
        {onCustomAttach ? (
          <PureButtonHelper
            className={cl(
              'relative inline-flex items-center px-4 py-2 rounded-l-md bg-blue-500 text-sm font-medium text-white hover:bg-blue-600 focus:z-10',
              withActionsPopover ? 'rounded-l-md' : 'rounded-md'
            )}
            dataGa="selected-products-set-attach-button"
            i18nText={words.attach}
            onClick={handleCustomAttach}
          />
        ) : (
          <SelectedProductsSidebarAttachModalButton
            optimisticUpdate={productsSet.optimisticUpdate}
            selectedProductIds={checkedSelectedProductIds}
            onClose={onClose}
            updateProductsSetCache={updateProductsSetCache}
            className={cl(
              'relative inline-flex items-center px-4 py-2 rounded-l-md bg-blue-500 text-sm font-medium text-white hover:bg-blue-600 focus:z-10',
              withActionsPopover ? 'rounded-l-md' : 'rounded-md'
            )}
            disabled={isEmpty(checkedSelectedProductIds)}
          />
        )}

        {withActionsPopover && (
          <SelectedProductsSidebarActionsPopover
            productsSet={productsSet}
            onClose={onClose}
            updateProductsSetCache={updateProductsSetCache}
          />
        )}
      </span>
    </div>
  );
}

export default SelectedProductsSidebarFooter;
