import isArray from 'lodash/isArray';
import omit from 'lodash/omit';

import { useReactQueryState } from '../../../common/hooks/base/reactQuery/useReactQueryState';

import {
  FetchProductsCacheKey,
  FetchProductsFilters,
  FetchProductsSort
} from '../../productsTypes';

import { INITIAL_PRODUCTS_SORT } from '../../productsConstants';

const ignoredFilterNames = ['productParentCategoryIds', 'productCategoryId'];

const defaultInitialFilters = { nda: false, blocked: false };

const defaultInitialSort = INITIAL_PRODUCTS_SORT;

interface ProductsFiltersCacheOptions {
  cacheKey: FetchProductsCacheKey;
  initialFilters?: FetchProductsFilters;
  initialSort?: FetchProductsSort;
}

function useProductsFiltersCache({
  cacheKey,
  initialFilters = defaultInitialFilters,
  initialSort = defaultInitialSort
}: ProductsFiltersCacheOptions) {
  const filtersCacheKey = `${cacheKey}-filters`;
  const sortCacheKey = `${cacheKey}-sort`;

  const { value: cachedProductsFilters, setValue } =
    useReactQueryState<FetchProductsFilters>(filtersCacheKey, initialFilters);

  const { value: cachedProductsSort, setValue: setCachedProductsSort } =
    useReactQueryState<FetchProductsSort>(sortCacheKey, initialSort);

  return {
    cachedProductsFilters,
    cachedProductsSort,
    changeCachedProductsFilters: (
      changedFilters: FetchProductsFilters,
      removedFilterNames: string[] = []
    ) => {
      setValue(
        omit({ ...cachedProductsFilters, ...changedFilters }, [
          ...(isArray(removedFilterNames) ? removedFilterNames : []),
          ...ignoredFilterNames
        ])
      );
    },
    setCachedProductsFilters: (nextFilters: FetchProductsFilters) => {
      setValue(omit(nextFilters, ignoredFilterNames));
    },
    setCachedProductsSort
  };
}

export default useProductsFiltersCache;
