import { gql } from 'graphql-request';
import {
  ProductID,
  ProductName,
  ProductUUID,
  ProductSku,
  ProductCategoryID,
  ProductBrandID,
  ProductImageID,
  ProductStyleID,
  ProductClientID,
  ProductMaterialID,
  ProductCollectionID,
  ProductColorID,
  ProductTagID,
  ProductStatusID,
  ProductMistakeTypeID,
  ProductFileVersionTagID,
  ProductRenderTypeID,
  ProductNda,
  ProductImageName,
  ProductImageFile,
  ProductImageSize,
  ProductImageUUID,
  ProductMaxFileName,
  ProductMaxFileSize,
  ProductMaxFileFile,
  ProductMaxFileUUID,
  ProductMaxFileID,
  ProductCategoryName,
  ProductBrandName,
  ProductStyleName,
  ProductClientFullName,
  ProductClientNanoId,
  ProductCollectionName,
  ProductMaterialName,
  ProductFileVersionTagName,
  ProductMistakeTypeName,
  ProductTagName,
  ProductColorName,
  ProductStatusName,
  ProductRenderTypeName,
  ProductNanoID,
  ProductFavorite,
  ProductBlocked,
  ProductClientCurrentTeamNanoId,
  ProductLifestyleID,
  ProductProductTypeID,
  ProductSeasonalID,
  ProductSeasonalName,
  ProductProductTypeName,
  ProductTaskID,
  ProductTaskNanoID,
  ProductTaskName,
  ProductCustomTagID,
  ProductCustomTagUUID,
  ProductCustomTagName,
  ProductMaxFileCreatedAt,
  ProductSelectedProductsCount
} from '../productsTypes';

export interface CacheFetchShowProductQueryResponseImage {
  id: ProductImageID;
  uuid: ProductImageUUID;
  file: ProductImageFile;
  size: ProductImageSize;
  name: ProductImageName;
}

export interface CacheFetchShowProductQueryResponseMaxFile {
  id: ProductMaxFileID;
  uuid: ProductMaxFileUUID;
  file: ProductMaxFileFile;
  size: ProductMaxFileSize;
  name: ProductMaxFileName;
}

export interface CacheFetchShowProductQueryResponse {
  id: ProductID;
  nanoId: ProductNanoID;
  uuid: ProductUUID;
  name: ProductName;
  sku: ProductSku;
  nda: ProductNda;
  image: CacheFetchShowProductQueryResponseImage;
  images?: CacheFetchShowProductQueryResponseImage[];
  maxFile?: {
    createdAt: ProductMaxFileCreatedAt;
  };
  maxFiles?: CacheFetchShowProductQueryResponseMaxFile[];
  previewModel?: CacheFetchShowProductQueryResponseMaxFile;
  favorite: ProductFavorite;
  blocked: ProductBlocked;
  productCategory?: {
    id: ProductCategoryID;
    localizedName: ProductCategoryName;
  };
  brand?: {
    id: ProductBrandID;
    localizedName: ProductBrandName;
  };
  style?: {
    id: ProductStyleID;
    localizedName: ProductStyleName;
  };
  clients: {
    id: ProductClientID;
    nanoId: ProductClientNanoId;
    fullName: ProductClientFullName;
    currentTeam: {
      nanoId: ProductClientCurrentTeamNanoId;
    };
  }[];
  collections: {
    id: ProductCollectionID;
    localizedName: ProductCollectionName;
  }[];
  materials: {
    id: ProductMaterialID;
    localizedName: ProductMaterialName;
  }[];
  colors: {
    id: ProductColorID;
    localizedName: ProductColorName;
  }[];
  tags: {
    id: ProductTagID;
    localizedName: ProductTagName;
  }[];
  statuses: {
    id: ProductStatusID;
    localizedName: ProductStatusName;
  }[];
  mistakeTypes: {
    id: ProductMistakeTypeID;
    localizedName: ProductMistakeTypeName;
  }[];
  fileVersionTags: {
    id: ProductFileVersionTagID;
    localizedName: ProductFileVersionTagName;
  }[];
  renderTypes: {
    id: ProductRenderTypeID;
    localizedName: ProductRenderTypeName;
  }[];
  lifestyles: {
    id: ProductLifestyleID;
  }[];
  productType: {
    id: ProductProductTypeID;
    localizedName: ProductProductTypeName;
  };
  seasonal: {
    id: ProductSeasonalID;
    localizedName: ProductSeasonalName;
  };
  task?: {
    id: ProductTaskID;
    nanoId: ProductTaskNanoID;
    name: ProductTaskName;
  };
  customTags: {
    id: ProductCustomTagID;
    uuid: ProductCustomTagUUID;
    name: ProductCustomTagName;
  }[];
  selectedProductsCount: ProductSelectedProductsCount;
}

export const CACHE_FETCH_SHOW_PRODUCT_QUERY = gql`
  query CacheShowProduct($uuid: ID!, $source: String) {
    product(uuid: $uuid, source: $source) {
      id
      nanoId
      uuid
      name
      sku
      nda
      favorite
      blocked
      productType {
        id
        localizedName
      }
      seasonal {
        id
        localizedName
      }
      productCategory {
        id
        localizedName
      }
      brand {
        id
        localizedName
      }
      image {
        id
        uuid
        size
        name
        file
      }
      images {
        id
        uuid
        file
        size
        name
      }
      maxFile {
        createdAt
      }
      maxFiles {
        id
        uuid
        file
        size
        name
      }
      previewModel {
        id
        uuid
        file
        size
        name
      }
      style {
        id
        localizedName
      }
      clients {
        id
        nanoId
        fullName
        currentTeam {
          nanoId
        }
      }
      collections {
        id
        localizedName
      }
      materials {
        id
        localizedName
      }
      colors {
        id
        localizedName
      }
      tags {
        id
        localizedName
      }
      statuses {
        id
        localizedName
      }
      mistakeTypes {
        id
        localizedName
      }
      fileVersionTags {
        id
        localizedName
      }
      renderTypes {
        id
        localizedName
      }
      lifestyles {
        id
      }
      task {
        id
        nanoId
        name
      }
      customTags {
        id
        uuid
        name
      }
      selectedProductsCount
    }
  }
`;
