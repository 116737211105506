import { useCallback } from 'react';

import {
  FetchProductsCacheKey,
  FetchProductsFilters,
  FetchProductsGqlQuery,
  FetchProductsLimit,
  FetchProductsSort,
  ProductNanoID
} from '../../productsTypes';
import {
  FetchCacheItemSources,
  FetchCacheItemsSources,
  FetchCacheItemsTrackTotalHits,
  UUID
} from '../../../../types';

import {
  CacheInfiniteIndexQueryBaseNodeType,
  CacheInfiniteIndexQueryWithFetchItemOptions,
  CacheInfiniteIndexQueryWithoutFetchItemOptions,
  useCacheInfiniteIndexQuery
} from '../../../common/hooks/base/reactQuery/useCacheInfiniteIndexQuery';

import {
  INITIAL_PRODUCTS_FILTERS,
  INITIAL_PRODUCTS_LIMIT,
  INITIAL_PRODUCTS_SORT,
  ProductsPermissions
} from '../../productsConstants';

type CacheProductsWithPrefetchItemOptions =
  CacheInfiniteIndexQueryWithFetchItemOptions;

type CacheProductsWithoutPrefetchItemOptions =
  CacheInfiniteIndexQueryWithoutFetchItemOptions;

interface CacheProductsDefaultOptions {
  cacheKey: FetchProductsCacheKey;
  query: FetchProductsGqlQuery;
  cacheQuery?: FetchProductsGqlQuery;
  initialFilters?: FetchProductsFilters;
  initialSort?: FetchProductsSort;
  initialLimit?: FetchProductsLimit;
  options?: {
    cacheTime?: number;
    staleTime?: number;
    enabled?: boolean;
    enabledPlaceholder?: boolean;
  };
  source?: FetchCacheItemsSources;
  trackTotalHits?: FetchCacheItemsTrackTotalHits;
}

type CacheProductsOptions = CacheProductsDefaultOptions &
  (
    | CacheProductsWithoutPrefetchItemOptions
    | CacheProductsWithPrefetchItemOptions
  );

const scope = 'products';

function useCacheProducts<
  ProductItemType extends CacheInfiniteIndexQueryBaseNodeType
>({
  cacheKey,
  fetchCacheItemSource = FetchCacheItemSources.MNESIA,
  fetchItemCacheKey,
  fetchItemQuery,
  fetchCacheItemQuery,
  initialFilters = INITIAL_PRODUCTS_FILTERS,
  initialLimit = INITIAL_PRODUCTS_LIMIT,
  initialSort = INITIAL_PRODUCTS_SORT,
  options = {},
  query,
  cacheQuery,
  source = FetchCacheItemsSources.ES,
  trackTotalHits = false
}: CacheProductsOptions) {
  const {
    data,
    items,
    itemsError,
    itemsTotalCount,
    isFetched,
    isLoading,
    isFetchingNextPage,
    isPlaceholderData,
    currentPage,
    currentLimit,
    currentFilters,
    currentSort,
    hasNextPage,
    updateItemCache,
    loadMoreItems,
    filterItems,
    changeItemsFilters,
    clearItemsFilters,
    clearItemsFiltersPersistInitial,
    sortItems,
    prefetchItem,
    limitItems
  } = useCacheInfiniteIndexQuery<ProductItemType>({
    action: ProductsPermissions.READ_PRODUCTS_INDEX_ES_QUERY,
    cacheKey,
    cacheQuery,
    fetchCacheItemQuery,
    fetchCacheItemSource,
    fetchItemCacheKey,
    fetchItemQuery,
    initialFilters,
    initialLimit,
    initialSort,
    options,
    query,
    scope,
    source,
    trackTotalHits
  });

  return {
    productsData: data,
    products: items,
    productsError: itemsError,
    productsTotalCount: itemsTotalCount,
    productsFetched: isFetched,
    productsLoading: isLoading,
    productsFetchingNextPage: isFetchingNextPage,
    productsIsPlaceholderData: isPlaceholderData,
    productsFilters: currentFilters,
    productsSort: currentSort as FetchProductsSort,
    productsPage: currentPage,
    productsLimit: currentLimit,
    hasNextProductsPage: hasNextPage,
    updateProductCache: updateItemCache,
    loadMoreProducts: loadMoreItems,
    filterProducts: filterItems,
    changeProductsFilters: changeItemsFilters,
    clearProductsFilters: clearItemsFilters,
    clearProductsFiltersPersistInitial: clearItemsFiltersPersistInitial,
    sortProducts: sortItems,
    limitProducts: limitItems,
    prefetchProduct: useCallback(
      (productNanoId: ProductNanoID) => {
        const nanoId = productNanoId as unknown as UUID;
        prefetchItem(nanoId);
      },
      [prefetchItem]
    )
  };
}

export default useCacheProducts;
